<template>
  <EvaluationOfferListBase
    :tender="tender"
    :offers="offers"
    :offersEvaluations="offersEvaluations"
    :additionalHeaders="additionalHeaders"
    :isLoadingOffers="isLoadingOffers"
    contractSumTooltip="Den samlet tilbudte pris inkl. optioner"
    @fetchOffers="fetchOffers"
  >
    <template #additional-columns></template>
    <template #tco="{ btnColor }">
      <TcoDocumentationTrigger
        :btnColor="btnColor"
      />
    </template>
    <template #bpq="{ offer, index }">
      <BpqDocumentationDialog
        :offer="offer"
        :offerEvaluation="getOfferEvaluation(offer.id)"
        :index="index"
        :tender="tender"
      />
    </template>
  </EvaluationOfferListBase>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Offer, Tender, EvaluationOfferListBase, OfferEvaluation } from '@dims/components';
import BpqDocumentationDialog from './BpqDocumentationDialog.vue';
import TcoDocumentationTrigger from './TcoDocumentationTrigger.vue';

const emit = defineEmits<{ fetchOffers: [] }>();
const { isLoadingOffers = false, offersEvaluations } = defineProps<{
  tender: Tender,
  offers: Offer[],
  offersEvaluations: OfferEvaluation[],
  isLoadingOffers?: boolean,
}>();

const additionalHeaders = ref([]);

function getOfferEvaluation(offerId: string) {
  return offersEvaluations.find((oe) => oe.offerId === offerId);
}

function fetchOffers() {
  emit('fetchOffers');
}
</script>
