<template>
  <div>
    <DimsTooltip :dark="(index === 0 && !offer.isUnconditional) ? true : false" @click.stop="openDialog()">
      <p>Klik for at se dokumentation af udregningen.</p>
    </DimsTooltip>
    <v-dialog v-model="bpqDocumentationDialog">
      <v-container fluid>
        <v-card class="pa-6">
          <v-container>
            <h1>Udregning af pris pr. kvalitetspoint</h1>
            <br />
            <v-card-text class="pl-0">
              <h3 class="mt-0">Vægtning</h3>
              <div class="d-flex mt-0">
                <ul>
                  <li v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <strong>{{ criteria.label }}</strong>
                      <div class="ml-1">
                        {{ convertDecimalSeperator(getCriteriaPercentage(criteria)) }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <h3 class="mt-6">Point</h3>
              <div class="d-flex mt-0">
                <div>
                  <h4 class="mt-2">Score</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <strong>{{ criteria.label }}:</strong>
                      <div class="ml-1">
                        {{ criteria.score }} point
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-12">
                  <h4 class="mt-2">Vægtet</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <div class="ml-1">
                        {{
                          `${convertDecimalSeperator(getCriteriaPercentage(criteria))}
                          x ${criteria.score}
                            = ${convertDecimalSeperator(criteria.weightedScore)} point`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="d-flex">
                <strong>Samlet antal vægtet point:</strong>
                <div class="ml-1">
                  {{
                    `${`${totalWeightedScoreString} = `}${convertDecimalSeperator(calculateBPQRatioScore(
                      offer,
                      tender,
                      offerEvaluation,
                    ))} point`
                  }}
                </div>
              </div>
              <div v-if="isOfferConditional">
                <h3 class="mt-6">Resultat (pris pr. kvalitetspoint)</h3>
                <v-row class="mt-0">
                  <v-col class="pt-1">
                    <div>
                      {{
                        `${$format.currency2(
                          offer.data.tcoCalculationResult?.tcoPrice,
                        )} / ${convertDecimalSeperator(calculateBPQRatioScore(
                          offer, tender, offerEvaluation,
                        ))} point = `
                      }}
                      <span class="font-weight-bold double-underline ml-1">
                        {{
                          `${$format.currency2(
                            calculateTotalPricePerPoint(offer, tender, offerEvaluation),
                          )} / point`
                        }}
                      </span>
                    </div>
                  </v-col>
                </v-row>
              </div>
              <div v-else>
                <h3 class="mt-6">Ukonditionelt tilbud</h3>
                <v-row class="mt-0">
                  <v-col class="pt-1">
                    {{ unconditionalOfferText }}
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <div class="d-flex justify-center">
              <v-card-actions>
                <v-btn class="secondary-button" @click="closeDialog()">
                  Luk
                </v-btn>
              </v-card-actions>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { OfferEvaluation, WeightedScoreViewModel } from '@dims/components';
import {
  calculateBPQRatioScore,
  calculateTotalPricePerPoint,
  getCalculator,
  convertDecimalSeperator,
  convertDecimalSeperators,
} from '@/services/utils0219';
import { Offer0219 } from '@/models/Offer';
import { Tender0219 } from '@/models/Tender';
import bpqCriteria from '@/services/bpqCriteria';

const { offer, tender, offerEvaluation, index } = defineProps<{
  offer: Offer0219,
  tender: Tender0219,
  offerEvaluation?: OfferEvaluation,
  index: number }>();
const bpqDocumentationDialog = ref(false);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const criterias = ref<WeightedScoreViewModel[]>(null!);

function getCriteriaPercentage(criteria: WeightedScoreViewModel) {
  if (offer.isEvaluated && offerEvaluation) {
    const criteriaEnum = bpqCriteria.criteriaStringToEnum(criteria.key);

    if (criteriaEnum) {
      const weight = bpqCriteria.getWeight(tender.data, criteriaEnum);

      if (weight) {
        return weight;
      }
    }
  }

  return 0;
}

function openDialog() {
  bpqDocumentationDialog.value = true;

  if (offer.isEvaluated && offerEvaluation) {
    criterias.value = bpqCriteria.getCategories(tender.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  } else {
    criterias.value = bpqCriteria.getEmptyCategories(tender.data)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }
}

function closeDialog() {
  bpqDocumentationDialog.value = false;
}

const totalWeightedScoreString = computed(() => {
  const values = criterias.value.map((c) => c.weightedScore.toString());

  return convertDecimalSeperators(values.join(' + '));
});

const isOfferConditional = computed(() => {
  if (offer.state !== 'Unconditional'
        && !(offer.isUnconditional ?? false)
        && !getCalculator().isScoreBelowConditionalThreshold_BackendCalculation(offer)
        && !getCalculator().isScoreBelowConditionalThreshold_FrontendCalculation(offer, tender)) {
    return true;
  }

  return false;
});

const unconditionalOfferText = computed(() => {
  const offerScore = convertDecimalSeperator(calculateBPQRatioScore(offer, tender, offerEvaluation));

  if (offer.isUnconditional) {
    return `Tilbuddet er markeret som ukonditionelt af ordregiver (det har opnået ${offerScore} point ifht. indkøbets krævede min. antal point ${tender.data.bpqPointScoreMinimum} point).`;
  }

  return `Tilbuddet er blevet forkastet, da det har opnået ${offerScore} point hvilket er mindre end indkøbets krævede min. antal point (${tender.data.bpqPointScoreMinimum} point).`;
});

</script>

<style scoped>

.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}

</style>
